<template>
	<div
		class="flex-column full-height"
	>
		<div
			class="bg-title"
		>
			<button
				:title="program.name"
				@click="toBack()"
			><v-icon large class="">mdi-chevron-left</v-icon><span class=" font-weight-bold size-em-15 vertical-middle">{{ program.name }}</span></button>
		</div>
		<div
			class="pa-10 overflow-y-auto"
			:inner-html.prop="mall_info.site_temrs | nl2br"
		>

		</div>
	</div>
</template>


<script>

export default{
	name: 'AppLogin'
	,props: ['Axios', 'codes', 'rules', 'mall_info', 'shop_info']
	, components: {  }
	,data: function(){
		return {
			program: {
				name: '이용약관'
				, code: 'agree'
				, top: false
				, title: true
				, bottom: false
				, class: 'white'
				, wrap: 'join'
				, callBack: {
					name: 'info'
					, event: 'prev'
				}
			}
		}
	}
	, methods: {

		toBack: function(){
			this.$router.back()
		}
	}
	,created: function(){
		this.$emit('setProgram', this.program)
	}
}
</script>

<style>

</style>